.font-gray-5, .block-heading-1 span {
  color: #b8b8b8; 
}
.font-gray-7, body {
  color: #939393; 
}
.text-black {
  color: #000 !important; 
}
.site-logo {
  background-image: url('/images/logo_fondo_blanco_grande.png');
  height: 90px;
  width: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.nav-item .nav-link {
  color: #000000;
}
.nav-item .nav-link.active  {
  color: #51eaea !important;
  display: inline-block;
  border-bottom: 2px solid #51eaea; 
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(81,234,234, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.site-section-cover {
  position: relative; 
}
.site-section-cover, .site-section-cover .container > .row {
  height: 100vh;
  min-height: 700px; 
}
.site-section-cover.img-bg-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right; 
  background-image: url('/images/person-transparent-2.png'); ;
}
.site-section-cover .img-absolute {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2; 
}
.site-section-cover h1 {
  font-size: 2.5rem;
  color: #51eaea;
  line-height: 1; 
}
.site-section-cover p {
  font-size: 18px;
  color: #999999;
  max-width: 400px; 
}
@media (max-width: 991.98px) {
  .site-section-cover .img-absolute {
    position: relative;
    right: auto; }
  .site-section-cover .img-absolute img {
      max-width: 100%; } 
}
@media (max-width: 991.98px) {
  .site-section-cover .box-shadow-content {
    background: rgba(255, 255, 255, 0.5);
    padding: 30px; }
    .site-section-cover .box-shadow-content p {
      color: #000; } 
}
.site-section {
  padding: 7em 0; 
}


.block-heading-1 span {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .1em;
  font-weight: bold; 
}

.block-heading-1 h2 {
  font-size: 3rem;
  color: #51eaea; 
}

.block-feature-1-wrap {
  margin-bottom: 7rem; 
}
.block-feature-1-wrap > .container > .row {
  margin-left: -18px;
  margin-right: -18px; 
}
.block-feature-1-wrap > .container > .row > .col,
.block-feature-1-wrap > .container > .row > [class*="col-"] {
  padding-right: 36px;
  padding-left: 36px; 
} 
.block-feature-1 {
  background: #f2f2f2;
  padding: 30px 30px 30px 50px;
  position: relative; 
}
.block-feature-1 h2 {
  font-size: 18px; 
}
.block-feature-1 .icon {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: -40px;
  top: 50%;
  border: 4px solid #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center; 
}
.block-feature-1 .icon > span {
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); 
}
.gradient-bg-1, .block-feature-1 .icon, .block-img-video-1 > a .icon {
  background: #51eaea;
  background: -moz-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #51eaea), color-stop(100%, #499bea));
  background: -webkit-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -o-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -ms-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#51eaea), to(#499bea));
  background: linear-gradient(to bottom, #51eaea 0%, #499bea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51eaea', endColorstr='#499bea', GradientType=0 ); }
  .block-feature-2 {
    padding: 30px 30px 30px 70px;}
    .block-feature-2 .icon {
      width: 60px; 
      height: 60px;
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      left: -12px;
      top: 50%;
      border: 4px solid #fff;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center; }

  .cookieAdvice {
  background-color: black;
  text-align:center;
  bottom:0;
  position:fixed;
  width:100%;
  z-index:1000;
}
.link-button {
  color: #61dafb;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}

.form-control {
  border: none;
  -webkit-box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.2) !important; 
}
.form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.2) !important; 
}
.wecallyou-shorcut {
  bottom: 50%;
  right: 0%;
  position: fixed;
}
.wecallyou-shorcut .icon {
  background-color: #61dafb;
  width: 60px;
  height: 60px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: -62px;
  top: 50%;
  border: 4px solid #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center; }
.wecallyou-shorcut .icon > span {
    font-size: 30px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
}
.site-footer {
  padding: 1em 0;
  background: #333333; }
  @media (min-width: 768px) {
    .site-footer {
      padding: 3em 0; } }
  .site-footer .border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .site-footer p {
    color: #737373; }
  .site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5 {
    color: #fff; }
  .site-footer a {
    color: #999999; }
    .site-footer a:hover {
      color: white; }
  .site-footer ul li {
    margin-bottom: 10px; }
  .site-footer .footer-heading {
    font-size: 16px;
    color: #fff; }

.articulo {
  margin-left: 1cm;
  margin-right: 1cm;
  text-align: justify;
}
.articulo .block-heading-1{
  text-align: center;
}
.articulo .h3 {
   font-weight: bold;
}
.articulo img {
  max-width: 100%;
  height: auto;
  display:block;
  margin:auto;
  text-align: center;
}